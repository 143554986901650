import { memo } from "react";
import { Image } from "primereact/image";

const RegresoAlGym = ({ programCode }) => (
  <div className="bg-Crayola rounded-lg py-4 px-5">
    <div className="flex items-center w-full space-x-3.5">
      <div className="h-[150px] flex-[0.85]">
        <Image
          key={`image-${programCode}`}
          alt="Image"
          src={programCode === "fitmom-embarazo" ? require("../../../assets/images/motivos_cancelacion/opcion03.png") : require("../../../assets/images/motivos_cancelacion/opcion04.png")}
          imageClassName="w-full h-full object-contain"
        />
      </div>
      <div className="flex flex-col flex-1 w-full space-y-3">
        <p className="text-white text-[11px] font-golos leading-[14px] text-left">
          Nuestra recomendación:
        </p>
        <p className="text-white text-[16px] font-golos-semibold leading-[23px] text-left whitespace-pre-line">
          ¿Sabías que tenemos 3 programas para diferentes etapas de la maternidad?
        </p>
      </div>
    </div>
  </div>
);

export default memo(RegresoAlGym, (prev, next) => prev.programCode === next.programCode);